import React, {useState, useEffect,useContext, useRef, DetailedHTMLProps, HTMLAttributes, memo, useLayoutEffect, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { styled as MUIStyled} from '@mui/system';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArticleIcon from '@mui/icons-material/Article';
import Typography from '@mui/material/Typography';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { Document, pdfjs } from "react-pdf";
import TerminalIcon from '@mui/icons-material/Terminal';
import Cutebot from "../static/images/cute_bot_transparent.png"
import MaritacaLogo from "../static/images/maritaca_logo.png"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; 
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChatControlContext, { ChatControlProviderProps } from '../shared/context/ChatControlContext';
import { 
    API_BASE, COMPANY_WEBSITE, TOAST_CONFIG, 
    PRODUCT_TERMS_AND_POLICY, codeLanguageSubset, 
    PLATFORM_WEBSITE 
} from '../shared/Constants';
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer,toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { SSE } from "../utils/sse.js";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex';
import rehypeHighlight from 'rehype-highlight';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../components/code/CodeBlock';
import BalanceIcon from '@mui/icons-material/Balance';
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import BillingDialog from '../components/BillingDialog';
import ReplayIcon from '@mui/icons-material/Replay';
import StopIcon from '@mui/icons-material/Stop';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import maritacaLogo from '../static/images/maritaca_logo.png';
import CodeIcon from '@mui/icons-material/Code';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import presetPrompts, { Prompt }  from '../shared/PresetPrompts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Collapse from '@mui/material/Collapse';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { ClickAwayListener } from '@mui/base';
import { Button } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function App() {
  useEffect(() => {
    const checkStatus = async () => {
      const endpoint= API_BASE + "/api/status";

      const response = await fetch(endpoint);
      if (response.status == 200) {
        const data= await response.json();
        if (data.message !== ""){

          toast.info(data.message, TOAST_CONFIG);
        }
      }
    };
    if (isAuthenticated){
      checkStatus();
    }
  }, []);

  const MessageAreaDivRef = useRef<HTMLDivElement>(null);

  const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently} = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [openBillingDialog, setOpenBillingDialog] = useState(false);

  const [messageBeingGenerated, setMessageBeingGenerated] = useState<string>("");
  const [loadingGeneration , setLoadingGeneration] = useState<boolean>(false);

  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const [hasMoreChats, setHasMoreChats] = useState<boolean>(true);
  const [loadingChatHistory, setLoadingChatHistory] = useState<boolean>(false);

  const [numPages, setNumPages] = useState<{ [key: string]: number } | null>(null);

  //Variables for rename chat functionality
  const [isFirstMessage, setIsFirstMessage] = useState<boolean>(true);
  const [animatedTitle, setAnimatedTitle] = useState("");

  //Variables for new chat functionality
  const [chatStatus, setChatStatus] = useState<{ [key: string]: boolean }>({}); 

  //Variables for feedback functionality
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null); // Stores the index of the message receiving a like or dislike
  const [showFeedback, setShowFeedback] = useState(false); // Controls the visibility of the feedback modal with reasons
  const [messageFeedback, setMessageFeedback] = useState<{[messageId: number]: string}>({});

  const FeedbackInline = ({ open, handleClose, handleSendFeedback, position, messageId }: 
    { open: boolean, handleClose: (messageId: number) => void, handleSendFeedback: (messageId: number, reason: string) => void, position: { top: number, left: number }, messageId: number }) => {
    
    const [selectedReason, setSelectedReason] = useState<string>('');
  
    const reasons = [
      "Recusou quando não deveria",
      "Resposta estava incorreta",
      "Verbosidade excessiva",
      "Não seguiu totalmente as instruções",
      "Alucinou informações",
    ];
  
    if (!open) return null;
  
    return (
      <ModalOverlay onClick={() => handleClose(messageId)}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <IconButton
            onClick={() => handleClose(messageId)}
            sx={{
              position: 'absolute',
              top: '2px',
              right: '4px',
            }}
          >
            <CloseIcon />
          </IconButton>
  
          <Typography variant="subtitle2" sx={{ marginBottom: '0.9rem', marginTop: '10px', fontSize: '0.85rem', fontWeight: 700 }}>
            Escolha um motivo:
          </Typography>
          {reasons.map((reason, index) => (
            <Button
              key={index}
              variant={selectedReason === reason ? 'contained' : 'outlined'}
              onClick={() => setSelectedReason(reason)}
              sx={{
                margin: '4px 0',
                fontSize: '0.7rem',
                padding: '4px 8px',
                width: '100%',
                textTransform: 'none',
              }}
            >
              {reason}
            </Button>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSendFeedback(messageId, selectedReason)}
            disabled={!selectedReason}
            sx={{
              marginTop: '8px',
              fontSize: '0.75rem',
              padding: '4px',
              width: '100%',
              textTransform: 'none',
            }}
          >
            Enviar Feedback
          </Button>
        </ModalContent>
      </ModalOverlay>
    );
  };
  
  const updateChatStatus = (chatId: string) => {
    setChatStatus((prevStatus) => ({
        ...prevStatus,
        [chatId]: true,
    }));
  };

  const animateTitle = (title: string) => {
    let index = 0;
    const interval = setInterval(() => {
      setAnimatedTitle(title.slice(0, index + 1));
      
      index++;
      if (index === title.length) {
        clearInterval(interval);
      }
    }, 100);
  };

  const dropdownMenuOptions: string[] = ['sabiazinho-3', "sabia-3"];
  const modelDescriptions: {[key: string]: [string, string[]]} = {
    'sabiazinho-3': ['Sabiazinho-3', ['Maior velocidade', 'Versão 2024-11-13']],
    'sabia-3': ['Sabiá-3', ['Modelo mais avançado', "Versão 2024-09-09"]]
  };
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  
  const { 
    activeChatID, 
    messageHistory, 
    userChats,
    modelChat,
    loadingHistory,
    pusherObj,
    setMessageHistory, 
    setUserChats, 
    setActiveChatID,
    setModelChat } = useContext(ChatControlContext) as ChatControlProviderProps; 

  const endOfChatMessageMarkerRef = useRef<HTMLDivElement>(null);

  const isMobile = window.innerWidth <= 600;

  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [messageBeingEdited, setMessageBeingEdited] = useState<string>("");
  const [editingMessageId, setEditingMessageId] = useState<number | null>(null);
  const [userHasActiveSubscription, setUserHasActiveSubscription] = useState<boolean>(false);
  
  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);

  const [showActionButtons, setShowActionButtons] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

  /* dynamically adjusts the height of a textarea element to fit its content when a specific message is being edited */
  useEffect(() => {
    if (editingMessageId !== null) {
      const textarea = document.getElementById(`edit-textarea-${editingMessageId}`);
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  }, [messageBeingEdited, editingMessageId]);

  const [sseConnection, setSseConnection] = useState<any>(null);

  useEffect(() => {
    return () => {
      if (sseConnection) {
        sseConnection.close();
      }
    };
  }, [sseConnection]);


  const smoothScrollToEnd = () => {
    if (endOfChatMessageMarkerRef.current) {
      endOfChatMessageMarkerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end'});
      setIsAtBottom(true);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const url = API_BASE + "/api/billing/user-configs";
      try {
        const access_token = await getAccessTokenSilently()
        const header = {
          "Content-Type": "application/json",
          "Authorization": `Auth ${access_token}`
        };
        const response = await fetch(url, {
          method: "GET",
          headers: header
        });
        if (response.ok) {
          const data = await response.json();
          setUserHasActiveSubscription(data.active_subscription);
        }
      } catch (error) {
        // When user's token expires getAccessTokenSilently() Timeouts...
      }
    };
  
    fetchData();
  }, []);

  const instantScrollToEnd = () => {
    if (endOfChatMessageMarkerRef.current) {
      endOfChatMessageMarkerRef.current.scrollIntoView({ behavior: 'auto' , block: 'end'});
    }
  };
  
  useLayoutEffect(() => {
    instantScrollToEnd();
    setIsAtBottom(true);
  }, [endOfChatMessageMarkerRef.current, activeChatID]);

  useEffect(() => {
    if (isAtBottom && messageBeingGenerated) {
      smoothScrollToEnd();
    }
  }, [messageBeingGenerated]);

  useEffect(() => {
    const handleScroll = () => {
      if (MessageAreaDivRef.current) {
        const buffer = 50; 
        const container = MessageAreaDivRef.current;
        const isBottom = container.scrollTop + container.clientHeight + buffer >= container.scrollHeight;
        setIsAtBottom(isBottom);
      }
    };

    if (MessageAreaDivRef.current) {
      MessageAreaDivRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (MessageAreaDivRef.current) {
        MessageAreaDivRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (!isLoading && !isAuthenticated) {
    logout({
      logoutParams: { returnTo: window.location.origin + "/auth" } 
    });
  }

  const userPicture = user?.picture || Cutebot;
  
  const handleNewMessageInputChange = (event: any) => {
    setCurrentMessage(event.target.value);
  };
  
  const handleEditMessageInputChange = (event: any) => {
    setMessageBeingEdited(event.target.value);
  };

  const code = memo((props: any) => {
    const { inline, className, children } = props;
    const match = /language-(\w+)/.exec(className || '');
    const lang = match && match[1];
  
    if (inline) {
      return <code className={className}>{children}</code>;
    } else {
      return <CodeBlock lang={lang || 'text'} codeChildren={children} />;
    }
  });
  
  const p = memo(
    (
      props?: Omit<
        DetailedHTMLProps<
          HTMLAttributes<HTMLParagraphElement>,
          HTMLParagraphElement
        >,
        'ref'
      > &
        any
    ) => {
      return <p className='whitespace-pre-wrap'>{props?.children}</p>;
    }
  );

  const formatAssistentMessage = (message: string) => {
    // check if ``` is present in the message

    
    return (
      <StyledReactMarkdown
              remarkPlugins={[
                remarkGfm,
                [remarkMath,  { singleDollarTextMath: false }],
              ]}
              rehypePlugins={[
                rehypeKatex,
                [
                  rehypeHighlight,
                  {
                    detect: true,
                    ignoreMissing: true,
                    subset: codeLanguageSubset,
                  },
                ],
              ]}
              components={
                {
                  code: code,
                  p: p,
                }
              }
            >
    {message}
      </StyledReactMarkdown>
    )
    
  }

  const PromptSuggestionsComponent = () => {
    const [randomCodePrompt] = useState<Prompt>(() => getRandomPromptSuggestion("Code"));
    const [randomWritingPrompt] = useState<Prompt>(() => getRandomPromptSuggestion("Creative Writing"));
    const [randomBrazilianPrompt] = useState<Prompt>(() => getRandomPromptSuggestion("Brazilian Knowledge"));

    // Only render component when there is an activeChatID or no message.
    return (
      !activeChatID["chatID"] || messageHistory.length === 0 ? (
        <MainInitialStripe>
          <StyledMaritacaLogo src={maritacaLogo} alt={"Maritaca Logo"} />
          <PromptsExamplesBox>
            <PromptExampleBox onClick={() => { handleSubmitSuggestion(randomCodePrompt.Prompt); }}>
              <PromptExampleIconCode />
              <PromptExampleTitle>{randomCodePrompt.Title}</PromptExampleTitle>
              <PromptExampleBody>{randomCodePrompt.Description}</PromptExampleBody>
            </PromptExampleBox>
            <PromptExampleBox onClick={() => { handleSubmitSuggestion(randomWritingPrompt.Prompt); }}>
              <PromptExampleIconCreative />
              <PromptExampleTitle>{randomWritingPrompt.Title}</PromptExampleTitle>
              <PromptExampleBody>{randomWritingPrompt.Description}</PromptExampleBody>
            </PromptExampleBox>
            <PromptExampleBox onClick={() => { handleSubmitSuggestion(randomBrazilianPrompt.Prompt); }}>
              <PromptExampleIconGeo />
              <PromptExampleTitle>{randomBrazilianPrompt.Title}</PromptExampleTitle>
              <PromptExampleBody>{randomBrazilianPrompt.Description}</PromptExampleBody>
            </PromptExampleBox>
          </PromptsExamplesBox>
        </MainInitialStripe>
      ) : null
    );
  };

  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // Send a message to our api.
  const sendMessage = async (chatId: string, message: string, token: string): Promise<any> => {
    const url = `${API_BASE}/api/chat/message`;
    const data = {
      chat_id: chatId,
      content: message,
      model: modelChat,
      is_user: true,
      files: selectedFiles ? await Promise.all(selectedFiles.map(async (file) => {
        const base64 = await getBase64(file) as string;
        return { filename: file.name, file_content: base64.toString().split(',')[1] };
      })) : [],
    }; 

    handleStartResponse(chatId, message, selectedFiles ? selectedFiles.map((file) => file.name) : undefined);
    setSelectedFiles(null);
    // reset the number of pages
    setNumPages(null);
    return new SSE(url, {
      headers: {
        authorization: `Auth ${token}`,
        "Content-Type": "application/json",
        Accept: "text/event-stream",
      },
      payload: JSON.stringify(data),
      method: 'POST',
    });
  };

  // Set state for message being generated.
  const handleStartResponse = (chatId: string, message: string, filenames?: string[]) => {
    setCurrentMessage("");
    setMessageBeingGenerated("");
    messageHistory.push({"chat_id": chatId, "role":"user", "content": message, "filenames": filenames});
    setLoadingGeneration(true);
  };

  const handleMessage = (e: any) => {
    const rawText = e.data;
    if (rawText.length > 0) {
      const payload = JSON.parse(rawText);
      const text = payload["text"];

      const container = MessageAreaDivRef.current;
      
      if (container) {
        const buffer = 50; // Adjust as needed
        const isBottom = container.scrollTop + container.clientHeight + buffer >= container.scrollHeight;

        if (text.length < 30) {
          // scroll to bottom in the begginning of the new message
          smoothScrollToEnd();
          setIsAtBottom(true);
        }

        if (text.length % 30 > 15 && isBottom) {
          // only run the function if scroll is already at the bottom
          smoothScrollToEnd();
          setIsAtBottom(true);
        }
      }
  
      const updatedHistory = [...messageHistory]; 
      const lastMessage: any = updatedHistory[updatedHistory.length - 1];
  
      if (lastMessage && lastMessage.role === 'assistant') {
        lastMessage.content = text;
      } else {
        updatedHistory.push({ role: 'assistant', content: text });
      }
  
      setMessageHistory(updatedHistory); 
      setMessageBeingGenerated(text);
    }
  };
  
  // Set state for arrived message.
  const handleEndResponse = () => {
    setLoadingGeneration(false);
    setShowActionButtons(true);
  };

  // Set event listeners for the inteire life cycle of a message call.
  const setupResponseHandlers = (response: any) => {
    response.addEventListener('message', handleMessage);
    response.addEventListener('end', handleEndResponse);
    response.addEventListener('error', handleError);
  };

  // Treat error ids during a message request.
  const handleError = (e: any) => {
    const status = e.data?.status;
    switch (status) {
      case 401:
      case 403:
        toast.error("Desculpe, você não parece ter permissão de fazer essa operação", TOAST_CONFIG);
        break;
      case 400:
        let responseText = "";
        try {
          responseText = JSON.parse(e.data["responseText"])["detail"];
        } catch {
          responseText = "";
        }

        if (responseText === "Message too long" || responseText === "The message is too long.") {
          toast.error("Desculpe, parece que sua mensagem é muito grande. Por favor, reduza a mensagem e tente novamente.", TOAST_CONFIG);
        } else {
          toast.error("Desculpe, algo deu errado. Por favor tente novamente mais tarde.", TOAST_CONFIG);
        }
        break;
      case 422:
        toast.error("Desculpe, ocorreu um erro ao processar seus arquivos, por favor verifique os mesmo.", TOAST_CONFIG);
        setLoadingGeneration(false);
        break;
      case 429:
        toast.error("Desculpe, parece que você mandou muitas mensagens em um curto periodo de tempo, aguarde um pouco e tente novamente", TOAST_CONFIG);
        break;
      case 503:
        toast.error("Desculpe, nossos servidores estão sob alta demanda, aguarde um pouco e tente novamente", TOAST_CONFIG);
        break;
      default:
        toast.error("Desculpe, aconteceu algo errado nos nossos servidores. Por favor tente mais tarde.", TOAST_CONFIG);
    }
  };

  const renameChatBasedOnMessage = async (chatId: string, message: string) => {
    const url = `${API_BASE}/api/chat/rename_based_on_message`; 
    const data = {
      chat_id: chatId,
      model: modelChat,
      content: message,
    };
    const acessToken = await getAccessTokenSilently();
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Auth ${acessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to rename chat');
    }

    const updatedChat = await response.json();
    animateTitle(updatedChat);  
    let find = false;

    const updatedChats = userChats.map((chat: any) => {
      if (chat.chat_id === chatId) {
        find = true;
        return {chat_id: chatId, title: updatedChat};
      }
      return chat;
    });
    if (find === false) {
      updatedChats.splice(0, 0, { chat_id: chatId, title: updatedChat });
    }  
    
    setUserChats(updatedChats);
    setIsFirstMessage(false);
    
  };
  
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    // Prevent multiple submissions
    if (loadingGeneration) {
      return;
    }
    setLoadingGeneration(true);
    setMessageBeingGenerated("");
  
    const processedMessage = currentMessage.trim();
  
    if (processedMessage.length === 0) {
      setLoadingGeneration(false);
      return;
    }
  
    let newConversation = false;
    let chatId = activeChatID["chatID"];
    if (!chatId) {
      chatId = await createNewChat();
      newConversation = true;
      if (!chatId) {
        setLoadingGeneration(false);
        return; 
      }
    }
  
    const totalPages = numPages ? Object.values(numPages).reduce((acc, val) => acc + val, 0) : 0;
    if (numPages && totalPages > 20) {
      toast.error(`Desculpe, você só pode enviar até 20 páginas por vez. Você tentou enviar ${totalPages} páginas.`, TOAST_CONFIG);
      setLoadingGeneration(false);
      return;
    }
  
    if (selectedFiles && selectedFiles.length > 3) {
      toast.error("Desculpe, você só pode enviar até 3 arquivos por vez.", TOAST_CONFIG);
      setLoadingGeneration(false);
      return;
    }
  
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await sendMessage(chatId, processedMessage, accessToken);
      if (!response) {
        setLoadingGeneration(false);
        return;
      }
  
      setupResponseHandlers(response);
      updateChatStatus(chatId);
      response.stream();
      setSseConnection(response);
      setIsFirstMessage(false);
      smoothScrollToEnd();
  
      if (newConversation) {
        await renameChatBasedOnMessage(chatId, processedMessage);
      }
    } catch (error) {
      setLoadingGeneration(false);
    }
  };
  

  const createNewChat = async () => {
    const url = API_BASE + "/api/chat/create";
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            "authorization": `Auth ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        const data = await response.json();
        if (data["chat_data"] != null) {
            const updatedChats = [data["chat_data"], ...userChats];
            setUserChats(updatedChats);
            setActiveChatID({
                "chatID": data["chat_data"]["chat_id"]
            });
            setChatStatus((prevStatus) => ({
                ...prevStatus,
                [data["chat_data"]["chat_id"]]: false,
            }));
            return data["chat_data"]["chat_id"];
        }
    } else {
        toast.error("Desculpe, algo deu errado, tente novamente", TOAST_CONFIG);
    }
    return null;
  };

  // Submit a message to our api from prompt suggestion card.
  const handleSubmitSuggestion = async (suggestedMessage: string) => {

    setMessageBeingGenerated("");
    const processedMessage = suggestedMessage.trim();

    setIsEditing(false);

    let newConversation = false;
    let chatId = activeChatID["chatID"];
    if (!chatId) {
      chatId = await createNewChat();
      newConversation = true;
      if (!chatId) return; 
    }

    const acessToken = await getAccessTokenSilently();
    const response = await sendMessage(chatId, processedMessage, acessToken);
    if (!response) return;

    setupResponseHandlers(response);
    updateChatStatus(chatId);

    response.stream();
    setSseConnection(response);
    setIsFirstMessage(false);
    if (newConversation) {
      await renameChatBasedOnMessage(chatId, processedMessage);
    }
  };

  // Sample a random prompt suggestion from our pool
  const getRandomPromptSuggestion = (category: string): Prompt => {
    const prompts = Object.values(presetPrompts[category]);
    const randomIndex = Math.floor(Math.random() * prompts.length);
    return prompts[randomIndex];
  }

  const handleCopyToClipboard = (text: string, index: number) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000); // reset the copied index after 2 seconds
    });    
  };
  
  const handleSaveEditedMessage = async (event: any) => {
    event.preventDefault();
    setIsEditing(false);

    if (editingMessageId === null) return;
  
    const requestBody = {
      chat_id: activeChatID.chatID,
      message_id: editingMessageId,
      new_content: messageBeingEdited,
      is_user: false,
      function_used: "edit",
    };
  
    const access_token = await getAccessTokenSilently();
    const url = `${API_BASE}/api/chat/edit_and_regenerate_message`;
  
    const response: any = new SSE(url, {
      headers: {
        "authorization": `Auth ${access_token}`,
        "Content-Type": "application/json",
        "Accept": "text/event-stream",
      },
      payload: JSON.stringify(requestBody),
      method: 'POST'
    });
  
    response.addEventListener('start', () => {
      const lastMessage: any = messageHistory[editingMessageId];
      if (lastMessage) {
        lastMessage.content = requestBody.new_content;
      }
      const assistantMessage: any = messageHistory[editingMessageId + 1];
      if (assistantMessage) {
        assistantMessage.content = "";
      }
      setMessageHistory([...messageHistory]); 
      setCurrentMessage("");
      setLoadingGeneration(true);
      setMessageBeingGenerated("");
    });
  
    response.addEventListener('message', (e: any) => {
      handleIncomingEditedMessage(e, editingMessageId);
    });
  
    response.addEventListener('end', handleEndResponse);
    setIsEditing(false);
    response.addEventListener('error', handleError);
  
    response.stream();
    setSseConnection(response);
  };
  
  // Handle incoming edited message.
  const handleIncomingEditedMessage = (e: any, editingMessageId: number) => {
    const rawText = e.data;
    if (rawText.length > 0) {
      const payload = JSON.parse(rawText);
      const text = payload["text"];
      const message: any = messageHistory[editingMessageId + 1];
      message.content = text; 

      const container = MessageAreaDivRef.current;
      if (container) {
        const isBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
        if (isBottom) {
          smoothScrollToEnd();
          setIsAtBottom(true);
        }
      }
  
      setMessageBeingGenerated(payload["text"]);
    }
  };

  const handleCancelEdit  = () => {
    setIsEditing(false);
    setEditingMessageId(null);
  };

  const handleEditMessage = (messageId: number, messageContent: string) => {
    setIsEditing(true);
    setEditingMessageId(messageId);
    setMessageBeingEdited(messageContent);
  };
    
  const handleRegenerateMessage = async (messageId: number) => {
    if (messageId === null) return;
    
    const originalMessage = (messageHistory[messageId] as any)?.content;
    if (!originalMessage) return;
  
    const requestBody = {
      chat_id: activeChatID.chatID,
      message_id: messageId,
      new_content: originalMessage,
      is_user: false,
      function_used: "regenerate",
    };
  
    const access_token = await getAccessTokenSilently();
    const url = `${API_BASE}/api/chat/edit_and_regenerate_message`;
  
    const response: any = new SSE(url, {
      headers: {
        "authorization": `Auth ${access_token}`,
        "Content-Type": "application/json",
        "Accept": "text/event-stream",
      },
      payload: JSON.stringify(requestBody),
      method: 'POST'
    });
  
    // Set event listeners for the entire life cycle of a message call.
    response.addEventListener('start', () => {
      const lastMessage: any = messageHistory[messageId];
      if (lastMessage) {
        lastMessage.content = originalMessage;
      }

      const assistantMessage: any = messageHistory[messageId + 1];
      if (assistantMessage) {
        assistantMessage.content = "";
      }
      setMessageHistory([...messageHistory]); // Trigger re-render
      setCurrentMessage("");
      setLoadingGeneration(true);
      
      setMessageBeingGenerated("");
    });
  
    response.addEventListener('message', (e: any) => {
      handleIncomingEditedMessage(e, messageId); // Reusing the function for edited messages
    });
  
    response.addEventListener('end', handleEndResponse);
    response.addEventListener('error', handleError);
  
    response.stream();
    setSseConnection(response);
  };

  const handleFeedback = async (messageId: number, feedback: string, reason: string) => {
    if (messageFeedback[messageId] === feedback) {
      return; // don't allow two lines with the same feedback
    }
  
    try {
      let chatId = activeChatID["chatID"];
      const url = `${API_BASE}/api/chat/message/feedback`;
      const access_token = await getAccessTokenSilently();
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "authorization": `Auth ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          message_idx: messageId,
          feedback: feedback,
          reason: reason,
        }),
      });
  
      if (response.ok) {
        setMessageFeedback((prev) => ({
          ...prev,
          [messageId]: feedback, 
        }));
      } else {
        throw new Error('Falha ao enviar feedback');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  const handleLikeMessage = (messageId: number) => {
    if (loadingGeneration) {
      return;
    }
    const message = messageHistory[messageId] as any;
    message.feedback = 'like';
    setMessageHistory([...messageHistory]);
    handleFeedback(messageId, 'like', '');
    setMessageFeedback((prev) => ({
      ...prev,
      [messageId]: 'like',
    }));
  };
  

  const handleStopClick = () => {
    if (sseConnection) {
      if (messageBeingGenerated.length > 30) {
        sseConnection.close(); 
        setLoadingGeneration(false); 
        setIsEditing(false);
        setShowActionButtons(true);
      }
    }
  };

  const handleNewChat = () => {
    if (!loadingGeneration) {
      setIsFirstMessage(true);
      setActiveChatID({
          "chatID": ""
      });
      setMessageHistory([]);
      setMessageBeingGenerated("");
      setIsEditing(false);
      setShowActionButtons(false);
      setAnimatedTitle("");
      // clean pdf files
      setSelectedFiles(null);
      setNumPages(null);
    }
  };

  const handleClose = (messageId: number) => {
    const updatedHistory = [...messageHistory];
    (updatedHistory[messageId] as any).feedback = 'dislike'; 
    setMessageHistory(updatedHistory);
    setShowFeedback(false);
    handleFeedback(messageId, 'dislike', '');
  };

  const handleChatItemClick = (chat_id:string) => {
    if (loadingGeneration===false){
      const chat = userChats.find((chat:any) => chat["chat_id"] === chat_id);
      setAnimatedTitle((chat as { title: string }).title);
      setMessageBeingGenerated("");
      setIsFirstMessage(false);
      setActiveChatID(
        {
          "chatID": chat_id
        }
      )
      instantScrollToEnd();
      
      setIsEditing(false); // reset the editing state
    }else {
      toast.warn("Por favor, espere a mensagem atual ser gerada antes de trocar de conversa", TOAST_CONFIG)
    }
  };

  const handlePlatformButton = ()=>{
    // redirect user to platform website
    window.open(PLATFORM_WEBSITE, "_blank");
  }

  const handleClickAwayDropdownMenu = () => {
    setOpenDropdownMenu(false);
  };
  
  const handleClearConversationButton= async ()=>{
    setIsFirstMessage(true);
    // redirect user to COMPANY_WEBSITE
    if (loadingGeneration){
      toast("Por favor, espere a mensagem atual ser gerada antes de limpar a conversa", TOAST_CONFIG)
    }

    if (window.confirm("Você tem certeza que quer apagar todas as conversas?")){
    
      const url = API_BASE + "/api/chat/clear";
      const access_token=await getAccessTokenSilently()
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "authorization": `Auth ${access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok){
        setUserChats([]);
        setActiveChatID({
          "chatID": ""
        });
        setMessageBeingGenerated("");
        setChatStatus({});
      }
    }
  }

  const handleRenameConversation= async (chatId: string, message: string)=>{
    
    if (!message.trim()) {
      return; // Do nothing if message is empty
    }
    if (message.length > 100) {
      toast.warn("O título da conversa não pode exceder 100 caracteres.", TOAST_CONFIG);
      return;
    }

    const url = `${API_BASE}/api/chat/rename`; 
    const data = {
      chat_id: chatId,
      name: message,
    };
    const acessToken = await getAccessTokenSilently();
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Auth ${acessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to rename chat');
    }

    let find = false;

    const updatedChats = userChats.map((chat: any) => {
      if (chat.chat_id === chatId) {
        find = true;
        return {chat_id: chatId, title: message};
      }
      return chat;
    });
    if (find === false) {
      updatedChats.splice(0, 0, { chat_id: chatId, title: message });
    }  
    
    setUserChats(updatedChats);
  }

  const handleDeleteConversation= async (chat_id:string)=>{
    // redirect user to COMPANY_WEBSITE
    if (loadingGeneration){
      toast.warn("Por favor, espere a mensagem atual ser gerada antes de deletar uma conversa", TOAST_CONFIG)
    }
    
    const url = API_BASE + `/api/chat/delete/${chat_id}`;
    const access_token=await getAccessTokenSilently()
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "authorization": `Auth ${access_token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok){
      const data=await response.json();
      setUserChats(data["chats"]);
      if (chat_id === activeChatID["chatID"]){
        setActiveChatID({
          "chatID": ""
        });
      }

      setChatStatus((prevStatus) => {
        delete prevStatus[chat_id];
        return prevStatus;
      });
      setMessageBeingGenerated("");
    }
  }
  

  const handleLogoutButton= ()=>{
    // redirect user to COMPANY_WEBSITE
    logout({ logoutParams: { returnTo: window.location.origin +"/auth" }})
  }


  const handleOpenBillingDialog= ()=>{
    setOpenBillingDialog(true);
  }

  const handleSidebarToggle= (state:boolean | undefined =undefined)=>{
    if (state == undefined){
      setOpenSidebar(!openSidebar);
    }else{
      setOpenSidebar(state);
    }
  }
  const handleButtonDownTextInput=async (e: any)=>{
    if (isMobile===true){
      return;
    }else{
      if(!loadingGeneration && currentMessage.trim().length>0 && e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault();
        handleSubmit(e)
      }
    }
  }

  const handleSelectOption = (option: string) => {

    // Don't change the inference model if one generation is already in progress.
    if (loadingGeneration)  {
      toast.error("Por favor, espere a mensagem atual ser gerada antes de trocar o modelo", TOAST_CONFIG)
      return;
    }
    else {
      if(modelChat == option)
        return;
      else{
        setModelChat(option);
        setOpenDropdownMenu(false);

        // If the chat window is populated, start a new chat windows when selecting a new model. 
        if(messageHistory.length != 0)
          setIsFirstMessage(false);
          handleNewChat()
          setShowFeedback(false);
      }
    }
  }

  const parseFilename = (filename: string) => {
    // Ensure the filename has more than 40 characters before truncating
    if (filename.length > 40) {
      return filename.substring(0, 30) + "..." + filename.substring(filename.length - 10);
    }
    return filename;
  };  

  const handleFileUpload = (event: any) => {
    // if it is not a pdf, don't allow the upload
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type !== "application/pdf") {
        toast.error("No momento, suportamos apenas PDFs.", TOAST_CONFIG);
        return;
      }
    }

    // Convert the existing selected files to a map with a unique key (name + lastModified)
    const fileMap = new Map<string, File>(
      (selectedFiles || []).map((file) => [`${file.name}-${file.lastModified}`, file])
    );

    // Iterate over the newly selected files
    Array.from(event.target.files as File[]).forEach((file) => {
      // Use the same unique key (name + lastModified) to check for duplicates
      const uniqueKey = `${file.name}-${file.lastModified}`;
      if (!fileMap.has(uniqueKey)) {
        fileMap.set(uniqueKey, file);
      }
    });

    setSelectedFiles(Array.from(fileMap.values()));

    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
    }
  };

  const handleLoadSuccess = (pdf: any, filename: string) => {
    setNumPages((prevNumPages) => {
        return {...prevNumPages, [filename]: pdf.numPages};
      }
    );
  };

  const handleFileDelete = (file: File) => {
    setSelectedFiles((selectedFiles || []).filter((selectedFile) => selectedFile !== file));
    // Remove the number of pages for the deleted file
    setNumPages((prevNumPages) => {
      const newNumPages = {...prevNumPages};
      delete newNumPages[file.name];
      return newNumPages;
    });
  }

  const getIconForModel = (model: string) => {
    if (model === 'sabiazinho-3') {
      return <SmallModelIcon />;
    } else if (model === 'sabia-2-medium') {
      return <MediumModelIcon />;
    } else {
      return <Sabia3ModelIcon />;
    }
  }

  // Controls logic to show "hovered" effect when chat is hovered.
  const [hoveredChatIndex, setHoveredChatIndex] = useState<number | null>(null);
  // Controls logic to show "hovered" effect when moreIcon of a chat is clicked.
  const [chatMenuClickedIndex, setChatMenuClickedIndex] = useState<number | null>(null);

  // Anchor for popup menu.
  const [anchorElChatMenu, setAnchorElChatMenu] = useState<null | HTMLElement>(null);

  // Save current state of chat to be edited.
  const [currentChatId, setCurrentChatId] = useState<string>("");
  const [currentChatTitle, setCurrentChatTitle] = useState<string>("");

  // Save state of editing chat.
  const [editingChatId, setEditingChatId] = useState<string | null>(null);
  const [editedChatTitle, setEditedChatTitle] = useState<string>("");

  // Reference to the chat rename styledTextField
  const styledTextFieldRef = useRef<HTMLInputElement>(null);

  // Component for chatMenu popup and its activation button (moreIcon).
  const ChatMenu = (index: number, chat_id: string, chat_title: string) => {
    const openChatMenu = Boolean(anchorElChatMenu);
    
    // Handle click on moreIcon that renders the chatMenu popup.
    const handleClickChatMenu = (event: React.MouseEvent<HTMLElement>, index: number, chat_id:string) => {
      setAnchorElChatMenu(event.currentTarget);
      setChatMenuClickedIndex(index);
      setCurrentChatId(chat_id);
      setCurrentChatTitle(chat_title);
    };
    
    const handleCloseChatMenu = () => {
      setAnchorElChatMenu(null);
      // Remove "hovered" effect from previously clicked chat.
      setChatMenuClickedIndex(null);
      setHoveredChatIndex(null);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation(); // Prevent the click event from propagating to parent elements
      handleCloseChatMenu();
    };

    const handleRenameClick = (chat_id: string, currentTitle: string) => {
      setEditingChatId(chat_id);
      setEditedChatTitle(currentTitle); // Pre-fill the input with the current title
      
      setTimeout(() => {
        styledTextFieldRef.current?.focus(); // Ensure the input is focused
        styledTextFieldRef.current?.select(); // Select all text within the input
      }, 0);
    };

    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            aria-label="more"
            id="open-ChatMenu"
            aria-controls={openChatMenu ? 'ChatMenu' : undefined}
            aria-expanded={openChatMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click event from bubbling up
              handleClickChatMenu(e, index, chat_id);
            }}
            disableRipple
            sx={{ p: 0 }}
          >
            <MoreHorizIcon sx={{ color: '#091758', fontSize: '22.5px' }} />
          </IconButton>
        </Box>
        <Menu
          id="ChatMenu"
          anchorEl={anchorElChatMenu}
          open={openChatMenu}
          onClose={(event: SyntheticEvent) => { // MUI returns a SyntheticEvent here...
            event.stopPropagation(); // Prevent the click event from bubbling up
            handleCloseChatMenu();
          }}
          PaperProps={{
            style: {
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              border: `1px solid #e5e7eb`,
              borderRadius: '5px',
              gap: '0.5rem',
              backgroundColor: 'white',
            },
          }}
          MenuListProps={{
            'aria-labelledby': 'open-ChatMenu',
            sx: { padding: 0 }
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          TransitionComponent={Collapse}
        >
          <MenuItemPopup onClick={(e) =>{
            handleMenuItemClick(e);
            handleRenameClick(currentChatId, currentChatTitle);
            }} 
            disableRipple
          >
            <ModeEditOutlineIcon/> <span>Renomear</span>
          </MenuItemPopup>
          <MenuItemPopup onClick={(e) => {
            handleMenuItemClick(e); 
            handleDeleteConversation(currentChatId); 
          }}
            disableRipple
          >
            <DeleteOutlineIcon sx={{ color: 'red' }}/> <span style={{ color: 'red' }}>Apagar</span>
          </MenuItemPopup>
        </Menu>
      </React.Fragment>
    );
  };
  
  const handleCancelEditCancelMenu = () => {
    setEditingChatId(null); // Exit rename chat without saving
  };


  const handleDislikeClick = (event: React.MouseEvent<HTMLButtonElement>, messageId: number) => {
    if (loadingGeneration) {
      return;
    }
    setSelectedMessageId(messageId);
    setShowFeedback(true);
  };
  
  const handleSendFeedback = (messageId: number, reason: string) => {
    const updatedHistory = [...messageHistory];
    (updatedHistory[messageId] as any).feedback = 'dislike'; 
    setMessageHistory(updatedHistory);
    setShowFeedback(false);
    handleFeedback(messageId, 'dislike', reason);
  };

  useEffect(() => {
    if (!loadingGeneration) smoothScrollToEnd();
  }, [messageHistory]);

  // Checks for clicks outside of the rename chat menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (styledTextFieldRef.current && !styledTextFieldRef.current.contains(event.target as Node)) {
        handleCancelEditCancelMenu();
      }
    };


    // Attach event listener to document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchMoreChats = async () => {
    if (!hasMoreChats || loadingChatHistory) {
      return;
    }
    setLoadingChatHistory(true);
    try {
      const url = API_BASE + "/api/chat/list";
      const access_token = await getAccessTokenSilently();
      const offset = userChats.length;
  
      const response = await fetch(`${url}?offset=${offset}`, {
        method: 'GET',
        headers: {
          Authorization: `Auth ${access_token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data["chats"].length === 0) {
          setHasMoreChats(false);
        }
        setUserChats([...userChats, ...data["chats"]]);
      }
    } catch (error) {
      toast.error("Desculpe, algo deu errado, tente novamente", TOAST_CONFIG);
    } finally {
      setLoadingChatHistory(false);
    }
  };

  const sidebarContent = () => {
    const handleScroll = (e: any) => {
      const element = e.target;
      const buffer = 300; // Buffer of 50px
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + buffer) {
      // User has scrolled to the bottom
      fetchMoreChats();
      }
    };
  
    return (
      <> {/* Default model is set on "Começar uma nova conversa".*/}
        <SidebarCreateNewChatButton onClick={() => { handleNewChat(); setModelChat('sabia-3'); }}>
          <AddIcon style={{ fontSize: "1.4rem" }} />
          <CreateChatText>Nova conversa</CreateChatText>
        </SidebarCreateNewChatButton>
  
        <SidebarChatItemsArea onScroll={handleScroll}>
          {userChats.map((chat: any, index) => {
            let isEditing = editingChatId === chat["chat_id"];
            const selected =
              chat["chat_id"] === activeChatID["chatID"] ||
              hoveredChatIndex === index ||
              chatMenuClickedIndex === index ||
              isEditing;
  
            const handleSaveRename = (chat_id: string) => {
              handleRenameConversation(chat_id, editedChatTitle);
              setEditingChatId(null); // Exit chat rename menu.
            };
  
            return (
              <ChatSidebarItem
                key={index}
                selected={selected}
                onClick={() => handleChatItemClick(chat["chat_id"])}
                onMouseEnter={() => setHoveredChatIndex(index)}
                onMouseLeave={() => setHoveredChatIndex(null)}
              >
                {isEditing ? ( // Show InputText when renaming chat.
                  <StyledTextField
                    variant="outlined"
                    inputRef={styledTextFieldRef}
                    value={editedChatTitle}
                    onChange={(e) => setEditedChatTitle(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSaveRename(chat["chat_id"]);
                      if (e.key === 'Escape') {
                        setEditingChatId(null);
                        isEditing = false;
                      } // Allow user to cancel editing with Escape
                    }}
                    autoFocus
                  />
                ) : (
                  <span>{chat["title"]}</span>
                )}
                {selected && ChatMenu(index, chat["chat_id"], chat["title"])}
              </ChatSidebarItem>
            );
          })}
          {loadingChatHistory && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
        </SidebarChatItemsArea>
        <SidebarLowerSection>
          {userHasActiveSubscription && (
            <SidebarItemOption onClick={() => handleOpenBillingDialog()}>
              <AccountCircleIcon />
              <span> Meu plano</span>
            </SidebarItemOption>
          )}
          <SidebarItemOption onClick={() => handlePlatformButton()}>
            <TerminalIcon style={{ marginTop: "1px" }} />
            <span>
              Desenvolva com o Sabiá <ArrowOutwardIcon style={{ fontSize: "15px" }} />
            </span>
          </SidebarItemOption>
        </SidebarLowerSection>
      </>
    );
  };
  

  return (
    <>
      <ToastContainer />
      <BillingDialog open={openBillingDialog} setOpen={setOpenBillingDialog}/>
      <Sidebar>
        {sidebarContent()}
      </Sidebar> 
      <StylizedDrawer
        anchor="left"
        open={openSidebar}
        onClose={() => handleSidebarToggle(false)}
      >
        {sidebarContent()}
      </StylizedDrawer>
      <Content ref={MessageAreaDivRef}> 
        <NavBar>
          <ClickAwayListener onClickAway={handleClickAwayDropdownMenu} touchEvent={false}>
            <NavBarItem onClick={() => setOpenDropdownMenu(!openDropdownMenu)}>
              <span style={{fontWeight:'600', fontSize: '1.3rem'}}>
                {modelDescriptions[modelChat][0]}
              </span>
              <MuiIConDiv>
                <ExpandDropdown/>
              </MuiIConDiv>
            </NavBarItem>
          </ClickAwayListener>
          <UserMenu>
            <UserAvatar src={userPicture} alt="User Avatar" onClick={() => setOpenUserMenu(!openUserMenu)}/>
            {openUserMenu && (
                <ClickAwayListener onClickAway={() => setOpenUserMenu(false)}>
                  <UserMenuDropdown>
                    <UserMenuItem onClick={() => window.open(PRODUCT_TERMS_AND_POLICY,"_blank")}>Termos e condições</UserMenuItem>
                    <UserMenuItem onClick={() => handleClearConversationButton()}>Apagar todas as conversas</UserMenuItem>
                    <UserMenuItem onClick={() => handleLogoutButton()}>Sair</UserMenuItem>
                  </UserMenuDropdown>
                </ClickAwayListener>
              )              
            }
          </UserMenu>
          {openDropdownMenu && (
            <DrodownMenu>
              {dropdownMenuOptions.map((option) => (
                <DropdownMenuItem key={option} onClick={() => handleSelectOption(option)}>
                  <MuiIConDiv>
                    {getIconForModel(option)}
                  </MuiIConDiv>
                  <DropdownMenuItemText>
                    {modelDescriptions[option][0]}
                    {modelDescriptions[option][1].map((description) =>
                      <DropdownMenuItemDescription>
                        {description}
                      </DropdownMenuItemDescription>
                    )}
                  </DropdownMenuItemText>
                  {option === modelChat ? <MuiIConDiv><SelectedModelIcon/></MuiIConDiv> :
                    <MuiIConUnselectedModel><UnselectedModelIcon/></MuiIConUnselectedModel>}
                </DropdownMenuItem>
              ))}
            </DrodownMenu>
          )}
        </NavBar>
        {!openSidebar && <StylizedMenuIcon onClick={() => handleSidebarToggle(true)}/>}
        <MessagesArea>
        {messageHistory.map((message: any, index) => {
          if (message.role === "user") {
            const isLastOrSecondToLastMessage = index === messageHistory.length - 1 ? index === messageHistory.length - 1 : index === messageHistory.length - 2;

            return (
              <UserMessageStrip key={index}>
              {!isEditing && isLastOrSecondToLastMessage  && (
                <EditButtonIcon onClick={() => handleEditMessage(index, message.content)}>
                <StyledEditIcon />
                </EditButtonIcon>
              )}
              <UserMessageStripContentArea style={{ width: isEditing && isLastOrSecondToLastMessage ? "100%" : "auto", maxWidth: isEditing && isLastOrSecondToLastMessage ? "100%" : "80%" }}>
                <UserMessageStripTextArea>
                {isEditing && editingMessageId === index ? (
                  <form onSubmit={handleSaveEditedMessage}>
                  <EditTextArea
                    id={`edit-textarea-${index}`}
                    value={messageBeingEdited}
                    onChange={handleEditMessageInputChange}
                  />
                  <EditButtons>
                    <EditButton type="submit" disabled={(loadingGeneration) || messageBeingEdited.trim().length == 0}>Enviar</EditButton>
                    <EditButton type="button" onClick={handleCancelEdit}>Cancelar</EditButton>
                  </EditButtons>
                  </form>
                ) : (
                  <p>{message.content}</p>
                )}
                {!isEditing && message.filenames && message.filenames.length > 0 && (
                  // When there is a message being generated OR when this is not the last user message
                  // AND message generated is empty
                  // (!loadingGeneration || !isLastOrSecondToLastMessage) && messageBeingGenerated !== "" ? (
                  (!loadingGeneration || messageBeingGenerated !== "") || !isLastOrSecondToLastMessage ? (
                  <HistoryFilesContainer>
                    {message.filenames.map((filename: string) => (
                    <MessageFileContainer>
                      <StyledArticleIcon />
                      <Name>{parseFilename(filename)}</Name>
                    </MessageFileContainer>
                    ))}
                  </HistoryFilesContainer>
                  ) : (
                  // Message saying we are processing the files
                  <MessageFileContainer>
                    <CircularProgress size={20} color="inherit" />
                    <Name style={{marginLeft: "5px"}}>Processando arquivos...</Name>
                  </MessageFileContainer>
                  )
                )}
                </UserMessageStripTextArea>
              </UserMessageStripContentArea>
              </UserMessageStrip>
            )
          } else if (message.role === "assistant" && ((!messageBeingGenerated || index !== messageHistory.length - 1)))  {
            const isLastAssistantMessage = index === messageHistory.length - 1;
            return (
              <BotMessageStrip key={index} className={isLastAssistantMessage ? 'copy-always-visible' : ''}>
                <MessageStripContentArea>
                  <MessageStripImageArea>
                    <img src={MaritacaLogo} alt="Cutebot"/>
                  </MessageStripImageArea>
                  <MessageStripTextArea>
                    {formatAssistentMessage(message.content)}
                    {!loadingGeneration && (
                      <>
                      <ActionButtonsContainer className={isLastAssistantMessage ? 'copy-always-visible' : ''}>
                        <IconButton className="copy-icon" onClick={() => handleCopyToClipboard(message.content, index)}>
                          {copiedIndex === index ? <CheckCircleRoundedIcon /> : <ContentCopyIcon />}
                        </IconButton>
                        <IconButton className="like-icon" onClick={() => handleLikeMessage(index)}>
                            <ThumbUpOutlinedIcon 
                                color={(messageHistory[index] as any)?.feedback === 'like' ? 'primary' : 'inherit'} 
                            />
                        </IconButton>
                        <IconButton className="dislike-icon" onClick={(e) => handleDislikeClick(e, index)}>
                          <ThumbDownOutlinedIcon color={(messageHistory[index] as any)?.feedback === 'dislike' ? 'error' : 'inherit'} />
                        </IconButton>
                        {isLastAssistantMessage &&(
                          <IconButton onClick={() => handleRegenerateMessage(index-1)}>
                            <ReplayIcon />
                          </IconButton>
                        )}
                    </ActionButtonsContainer>
                    </>
                    )}
                  </MessageStripTextArea>
                  
                </MessageStripContentArea>
              </BotMessageStrip>
            )
          }
        }
      )}

      {/* especial strip for message being currently generated */}
      {
      messageBeingGenerated && (
        <BotMessageStrip key="generating" className="copy-always-visible">
          <MessageStripContentArea>
            <MessageStripImageArea>
              <img src={MaritacaLogo} alt="Cutebot" style={{ opacity: 0.9 }}/>
            </MessageStripImageArea>
            <MessageStripTextArea>
              {formatAssistentMessage(messageBeingGenerated)}
              <ActionButtonsContainer className={'copy-always-visible'}>
                {!loadingGeneration && (
                <>
                  <IconButton className="copy-icon" onClick={() => handleCopyToClipboard(messageBeingGenerated, messageHistory.length - 1)}>
                    {copiedIndex === messageHistory.length - 1 ? <CheckCircleRoundedIcon /> : <ContentCopyIcon />}
                  </IconButton>
                  <IconButton className="like-icon" onClick={() => handleLikeMessage(messageHistory.length - 1)}>
                    <ThumbUpOutlinedIcon color={(messageHistory[messageHistory.length - 1] as any)?.feedback === 'like' ? 'primary' : 'inherit'}/> 
                  </IconButton>
                  <IconButton className="dislike-icon" onClick={(e) => handleDislikeClick(e, messageHistory.length - 1)}>
                    <ThumbDownOutlinedIcon color={(messageHistory[messageHistory.length - 1] as any)?.feedback === 'dislike' ? 'error' : 'inherit'} />
                  </IconButton>
                  
                  {!loadingGeneration && (
                    <IconButton onClick={() => handleRegenerateMessage(messageHistory.length - 2)}>
                      <ReplayIcon />
                    </IconButton>
                  )}
                </>
                )}
              </ActionButtonsContainer>
            </MessageStripTextArea>
            
          </MessageStripContentArea>
        </BotMessageStrip>
      )
      }

      <EndOfChatMessageMarker ref={endOfChatMessageMarkerRef} />

        </MessagesArea>
        {PromptSuggestionsComponent()}

        <InputContainer>
          {(!loadingGeneration && !isAtBottom) && (
            <ScrollToBottomButton onClick={smoothScrollToEnd}>
              <StyledArrowDownwardIcon />
            </ScrollToBottomButton>
          )}
          <StylizedForm onSubmit={handleSubmit}>
            <UserInputContainer>
                {selectedFiles && selectedFiles.length > 0 && (
                  <AllFilesContainer>
                      {Array.from(selectedFiles).map((file: any) => (
                        <>
                          <FileContainer>
                            <StyledArticleIcon />
                            <Name>{file.name}</Name>
                            <FileInfo>- {numPages && (numPages[file.name] > 1 ? `${numPages[file.name]} páginas` : `${numPages[file.name]} página`)}</FileInfo>
                            <StyledRemoveIcon onClick={() => handleFileDelete(file)} />
                          </FileContainer>
                        </>
                      ))}
                  </AllFilesContainer>
                )}
              <SubContainer>
                {(modelChat === 'sabia-3' || modelChat === "sabiazinho-3") && (
                  <AttachSpan onClick={() => fileInputRef.current?.click()}>
                  <StyledAttachFileIcon/>
                  <input
                    ref={fileInputRef}
                    multiple
                    type="file"
                    accept=".pdf"
                    onChange={handleFileUpload}
                    style={{display: "none"}}
                  />
                  </AttachSpan>
                )}
                <MessagesContainerArea>
                  <InputTextArea
                    id="input-text-field"
                    multiline
                    placeholder="Envie uma mensagem"
                    onChange={handleNewMessageInputChange}
                    value={currentMessage}
                    maxRows={6}
                    onKeyDown={handleButtonDownTextInput}
                  />
                </MessagesContainerArea>
                <StyledIconButton
                  type="button"
                  aria-label="send"
                  onClick={loadingGeneration ? handleStopClick : currentMessage.trim() ? handleSubmit : () => {}}
                >
                  {loadingGeneration ? <StopIcon /> : <SendIcon />}
                </StyledIconButton>
              </SubContainer>
            </UserInputContainer>
          </StylizedForm>
        </InputContainer>
      </Content>
      {selectedFiles && selectedFiles.map((file, index) => (
        <Document
          key={index}
          file={file}
          onLoadSuccess={(pdf) => handleLoadSuccess(pdf, file.name)}
        />
      ))}
      {selectedMessageId !== null && (
        <FeedbackInline 
          open={showFeedback}
          handleClose={handleClose}
          handleSendFeedback={handleSendFeedback}
          position={modalPosition}
          messageId={selectedMessageId}
        />
      )}
    </>
  );
}

export default App;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const FileInfo = styled.span`
  font-size: 0.9rem;
  color: #464646;
  white-space: nowrap;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  gap: 10px;
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  max-width: 100%;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  padding-left: 10px;
  margin: 0;
  min-width: 100%;
  background-color: transparent;
  align-items: flex-end;
  justify-content: center;
`;

const StyledArrowDownwardIcon = MUIStyled(ArrowDownwardIcon)`
  font-size: 1.6rem;
  color: #5d5e61;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
  }
`;

const UserMenuDropdown = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const UserMenuItem = styled.div`
  padding: 10px;
  font-size: 1rem;
  color: #333;
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

const CreateChatText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-left: 5px;
  color: ${({theme})=> theme.sidebar_new_chat_color};
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const StyledEditIcon = MUIStyled(EditIcon)`
  font-size: 1.2rem;
  color: #5d5e61;

  &:hover {
    cursor: pointer;
    color: black;
  }
`;

const EditButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  padding: 5px;
  margin-top: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledMaritacaLogo = styled.img`
  width: 100px;
  height: 100px;
  opacity: 0.9;
  margin-bottom: 15px;
`;

const HistoryFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px;
  gap: 5px;
`;

const AllFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px;
  width: 100%;
  margin-bottom: 0px;
  gap: 10px;
  overflow-x: auto;


  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c0c0c0;
  }

  // style for scrollbar (Firefox)
  scrollbar-width: auto;
  scrollbar-color: #d4d4d4 #fafafa;
`;

const StyledArticleIcon = MUIStyled(ArticleIcon)`
  font-size: 2rem;
  align-self: center;
  color: #dd4d48;
  margin: 0px;
  padding: 0px;
  opacity: 0.8;
`;

const StyledRemoveIcon = MUIStyled(CloseIcon)`
  font-size: 1.5rem;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  &:hover {
    cursor: pointer;
    color: #919090;
  }
`;

const Name = styled.p`
  padding: 10px;
  padding-left: 0px;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0px 5px;
  margin-right: 0px;
`;

const FileContainer = styled.span`
  display: flex;
  background-color: transparent;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const MessageFileContainer = styled(FileContainer)`
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  margin-left: -5px;
  height: 2rem;
`;

const MessagesContainerArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: transparent;
  margin: 0px;
  width: 100%;
  max-width: 100%;
`;

const AttachSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledAttachFileIcon = MUIStyled(AttachFileIcon)`
  display: flex;
  font-size: 1.6rem;
  color: #5d5e61;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    background-color: transparent;
  }
`;

const StyledIconButton = MUIStyled(IconButton)`
  font-size: 1.5rem;
  background-color: transparent;
  margin-bottom: 2px;
  &:hover {
    cursor: pointer;
    background-color: transparent;
  }
`

const  MenuItemPopup = MUIStyled(MenuItem)`
  display: flex;
  cursor: pointer;

  line-height: 1.25rem;
  font-family: Arial;

  padding-right: 0.75rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  margin: 0.375rem;
`;

const MainInitialStripe = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 1.5rem;
  @media (max-width: 990px) {
    top: 40%;
  }
`;

const PromptsExamplesBox = styled.div`
  display: flex;
  max-width: 50vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

const PromptExampleBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0.75rem 0.75rem 1rem;
  border: 1px solid ${({theme})=> theme.prompt_suggestions_border};
  border-radius: 15px;
  gap: 0.5rem;
  width: 18rem;
  min-height: 7rem;
  max-height: 10rem;
  background-color: ${({theme})=> theme.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${({theme})=> theme.prompt_suggestions_hover};
  }
  cursor: pointer;
`;

const PromptExampleIconCode = MUIStyled(CodeIcon)`
  font-size: 1.5rem;
  color: #4884dd;
`;

const PromptExampleIconCreative = MUIStyled(AutoStoriesIcon)`
  font-size: 1.5rem;
  color: #cab38d;
`;

const PromptExampleIconGeo = MUIStyled(TravelExploreIcon)`
  font-size: 1.5rem;
  color: #66d462;
`;

const PromptExampleTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #585858;
`;

const PromptExampleBody = styled.span`
  font-size: 0.8rem;
  color: #747272;
`;

const EndOfChatMessageMarker = styled.div`
  opacity: 0;
  height: 100px;

`

const StylizedMenuIcon = styled(MenuIcon)`
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 100000;
  @media only screen and (min-width: 601px) {
    display: none !important;
  }

  @media only screen and (max-width: 600px) {
    display: inline-block !important;
    color: black;
    font-size: 2rem;
    cursor: pointer;
  }
`;

const StylizedDrawer = styled(Drawer)`

  @media only screen and (max-width: 600px) {
    & .MuiPaper-root {
      background-color: ${({theme})=> theme.sidebar_background};
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 70vw;
    }
  }

  
`
const Sidebar = styled.div`

  background-color: ${({theme})=> theme.sidebar_background};
  height: calc(100vh);
  width: 250px;
  position: fixed;
  top: 0px;
  z-index: 1;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 600px) {
    display: none !important;
  }

  
`
// Define the styled component for the main content area
const Content = styled.div`
  margin-left: 250px;
  position: absolute;
  width: calc(100vw - 250px);
  height: calc(100vh);
  max-height: 100vh
  background-color: ${({theme})=> theme.backgroundSoftWhite};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  
  @media only screen and (max-width: 600px) {
    width: 100vw;
    margin-left: 0px;
  }
`;

const SidebarChatItemsArea = styled.div`
  display: flex;
  flex-direction: column;
  touch-action: manipulation;
  flex: 1 0;
  overflow-y: auto;
  width: 100%;
  padding: 10px 10px;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c0c0c0;
  }

  // style for scrollbar (Firefox)
  scrollbar-width: auto;
  scrollbar-color: #d4d4d4 #ffffff;
`;

const SidebarItemOption= styled.div`
  display: flex;
  touch-action: manipulation;
  flex-direction: row;
  align-items: center;
  color: ${({theme})=> theme.sidebar_font_color};
  align-items: flex-start;
  width: 100%;
  padding: 10px 5px;
  border-radius: 5px;

  & > span {
    margin-left: 10px;
    margin-top: 4px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({theme})=> theme.sidebar_hover};
    transition: 0.2s;
  }

`

const SidebarLowerSection = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 5px;
  border-top: 1px solid #d0d1d1;
`;


const PrivacyButton = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: block;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: fixed;
  background: transparent;
  border-radius: 25px;
  bottom: 2rem;
  width: 55vw;
  max-width: 55vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &  textarea {
    max-width: 50vw;
    width: 100%;
    flex: 1;
  }

  @media only screen and (max-width: 600px) {
    width: 80vw;
    max-width: 550px;
  }
`;

const ProgressBarArea = styled.div`
  width: 100%;
`

const MessagesArea = styled.div`
  width: 55vw;
  max-width: 55vw;
  max-height: calc(100vh - 200px);
  margin-top: 60px;
  background-color: ${({theme})=> theme.backgroundSoftWhite};

  &  th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0.4rem;
  } 

  & table {
    margin: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  & thead {
    background-color: #1f2937;
    text-align: left;
    color: white;
    padding: 0.4rem;
  }

  & ol {
    list-style-type: inside;
    padding: 0px;
    padding-left: 1,625rem;
    margin: 0.2rem;
    white-space: wrap;
  }

  & ul {
    white-space: wrap;
  }

  & li {
    margin: 0.2rem 0;
    white-space: wrap;
    &::marker {
      padding-left: 0.5rem;
    }
  }

  & p {
    margin: 0.5rem 0;
    padding-left: 0;
    white-space: wrap;
  }
  @media only screen and (max-width: 600px) {
    width: 90vw;
    max-width: 90vw;
  }
`;

const UserMessageStrip = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: ${({theme})=> theme.backgroundSoftWhite};
  padding: 0px;
  margin: 0px;
`;

const MessageStripContentArea = styled.div`
  display: flex;
  justify-content: flex-start;
  color: black;
  width: 100%;
  max-width: 100%;
  margin: 0px;
`;

const UserMessageStripContentArea = styled.div`
  color: black;
  width: auto;
  max-width: 80%;
  margin: 0px;
  justify-content: flex-end;
  background-color: ${({theme})=> theme.user_message_background};
  border-radius: 20px;
  white-space: nowrap;
  overflow-wrap: break-word;
  @media only screen and (max-width: 900px) {
    margin-right: 10px;
    max-width: 80%;
    white-space: normal;
  }
`;

const MessageStripImageArea = styled.div`
  width: 3rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;


  & > img { 
    width: 3rem;
    height: 3rem;
  }

  @media only screen and (max-width: 900px) {
    width: 3rem;
    & > img { 
      width: 3rem;
      height: 3rem;
    }
  }
`;

const MessageStripTextArea = styled.div`
  display: flex;
  flex-direction: column;
  font-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 10px;
  max-width: 95%;
  overflow-wrap: break-word;

  @media only screen and (max-width: 600px) {
    max-width: 90%;
  }

  & p {
    white-space: pre-line;
  }
  
`;

const UserMessageStripTextArea = styled(MessageStripTextArea)`
  max-width: 100%;
`

const SidebarCreateNewChatButton = styled.button`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  margin-top: 10px;
  background-color: ${({theme})=> theme.sidebar_background};
  color: ${({theme})=> theme.sidebar_font_color};
  padding: 7px;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid #d1d3df;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;  

  &:hover {
    background-color: ${({theme})=> theme.sidebar_hover};
  }
`;

interface ChatSidebarItemProps {
  selected?: boolean;
}

const UserMenu = styled.div`
  cursor: pointer;
  display: flex;

  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: Arial;
  color: #747272;

  border-radius: 0.75rem;
  margin-top: 5px;
  gap: 0.25rem;

  align-items: center;
`;

const ChatSidebarItem = styled.div<ChatSidebarItemProps>`
  display: flex;
  height: 2.5rem;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  border-radius: 5px;
  color: ${({ theme }) => theme.sidebar_font_color};
  background-color: ${({ theme, selected}) => 
    selected ? theme.sidebar_selected : theme.sidebar_background};
  padding: 5px 5px;
  font-size: 1rem;
  cursor: pointer;

  & > span {
    max-width: 90%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > div {
    margin-left: auto;
  }

  &:hover {
    background-color: ${({ theme }) => theme.sidebar_selected}; 
  }
`;

const StyledTextField = styled(TextField)`
  width: calc(100% - 10px);

  & .MuiInputBase-root {
    font-size: 1rem;
    color: black;
    padding: 0;
  }

  & .MuiOutlinedInput-root {
    border-radius: 5px;
    background-color: transparent;
  }

  & .MuiInputBase-input {
    padding: 1px 1px 1px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  & .MuiInputBase-input::-webkit-input-placeholder {
    color: black;
  }
`;

const StylizedForm = styled.form`
  display: flex;
  flex:1;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  align-items: end;
  padding: 5px;
  background-color: #f5f5f5;
  margin:0 ;
`;


const InputTextArea = MUIStyled(TextField)`
  width: 100%;
  background-color: transparent;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  
  & .MuiInputBase-input {
    font-size: 1.2rem;
  }

  & .MuiOutlinedInput-root {
    padding: 12px 0;

  }

`;

const NavBar = styled.div`
  display: flex;
  position: fixed;
  left: 260px;
  top: 0;
  justify-content: space-between;

  width: calc(100vw - 270px);
  height: 50px;
  margin-bottom: 1rem;
  padding-left: 20px;
  padding-right: 10px;
  z-index: 1000;

  background-color: #ffff;

  @media (max-width: 600px) {
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    left: 30px;
    top: -3px;
  }
`;

const NavBarItem = styled.div`
  cursor: pointer;
  display: flex;

  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: Arial;
  color: ${({theme})=> theme.sidebar_font_color};

  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right:0.75rem;
  border-radius: 0.75rem;
  gap: 0.25rem;

  align-items: center;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const DrodownMenu = styled.div`
  position: absolute;
  z-index: 10000;
  top: calc(100% + 0.375rem);

  border-width: 1px;
  border-radius: 0.5rem;
  max-width: 30rem;
  min-width: 20rem;
  border: 0 solid #e3e3e3;

  background-color: #ffff;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
`;

const DropdownMenuItem = styled.div`
  display: flex;
  cursor: pointer;

  line-height: 1.25rem;
  font-family: Arial;
  
  padding-right: 0.75rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  gap: 0.5rem;
  margin: 0.375rem;
  border: 0 solid #e3e3e3;
  color: ${({theme})=> theme.sidebar_font_color};
  
  &:hover {
    background-color: #ececec;
  }
`;

const DropdownMenuItemText = styled.div`
  flex-grow: 1; 
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0.375rem;
`;

const DropdownMenuItemDescription = styled.div`
  display: flex;
  color: #797575;
  max-width: 18rem;
  font-size: 0.875rem;
`;

const MuiIConDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MuiIConUnselectedModel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9b9b9b;
`;

const  ExpandDropdown= MUIStyled(ExpandMoreIcon)`
  font-size: 1rem;
`;

const  SelectedModelIcon= MUIStyled(CheckCircleRoundedIcon)`
  font-size: 1.35rem;
`;

const  UnselectedModelIcon= MUIStyled(RadioButtonUncheckedRoundedIcon)`
  font-size: 1.35rem;
`;

const SmallModelIcon = MUIStyled(ElectricBoltRoundedIcon)`
  font-size: 1.35rem;
`;

const MediumModelIcon = MUIStyled(BalanceIcon)`
  font-size: 1.35rem;
`;

const Sabia3ModelIcon = MUIStyled(AutoAwesomeRoundedIcon)`
  font-size: 1.35rem;
`;

const EditTextArea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  background-color: ${({theme})=> theme.user_message_background};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: pre-wrap;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const EditButton = styled.button`
  padding: 0.5rem 1.3rem;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: ${({theme})=> theme.edit_button_color};
  color: white;
  &:hover {
    background-color: ${({theme})=> theme.edit_button_hover};
`;

const ScrollToBottomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

const BotMessageStrip = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  padding: 10px;
  position: relative;

  &.copy-always-visible .copy-icon,
  &.copy-always-visible .like-icon,
  &.copy-always-visible .dislike-icon {
    visibility: visible !important;
  }

  .copy-icon,
  .like-icon,
  .dislike-icon {
    visibility: hidden;
  }

  &:hover .copy-icon,
  &:hover .like-icon,
  &:hover .dislike-icon {
    visibility: visible !important;
  }

  @media (max-width: 600px) {
    .copy-icon,
    .like-icon,
    .dislike-icon {
      visibility: visible !important;
    }
  }
`;


const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  .copy-icon,
  .like-icon,
  .dislike-icon {
    visibility: hidden;
  }

  &:hover .copy-icon,
  &:hover .like-icon,
  &:hover .dislike-icon {
    visibility: visible !important;
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
`;

const ModalContent = styled(Box)`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px; 
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
`;